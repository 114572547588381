/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/bootstrap/custom.scss'
//import 'jquery/dist/jquery.min.js'
//import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util.js'
import 'bootstrap/js/dist/modal.js'
import 'bootstrap/js/dist/dropdown.js'
import 'bootstrap/js/dist/collapse.js'
//import 'bootstrap/dist/js/bootstrap.min.js'
